@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');


.App {
  /* padding: 20px; */
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  min-height: calc(100vh - 40px);
}

:root{
  --headingSize : 1.1rem;
  --headingWight : 600;
  --backOpacityColor : #009688ab;
  --backColor : #009688;
  --fontColor : #ffeb3b;
  --backDegree : 160deg;
  --back1Color : #4a4747;
  --back2Color : #000000;
  --back3Color : #000000;
  --back4Color : #000000;
  --back5Color : #000000;
}